import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CardList from './components/CardList';
import Cart from './components/Cart';
import CheckoutPage from './components/CheckoutPage';
import Infographics from './components/Infographics';
import EnquiryPopup from './components/EnquiryPopup';
import { translateText, isTranslationAvailable } from './utils/openaiTranslate';
import './App.css';

export const API_URL = 'https://kiosk.tcgsync.com';
console.log('API URL:', API_URL);
console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);

const DEFAULT_USER_SETTINGS = {
  conditions: ['NM', 'LP', 'MP', 'HP'],
  defaultCashPercentage: '50',
  defaultCreditPercentage: '60',
  enabledGames: ['Magic: The Gathering'],
  excludedSku: [],
  floorPrice: '0',
  bulkSettings: '',
  box1: '',
  currency: 'USD',
};

// Cache for storing fetched prices
const priceCache = new Map();

// Cache for storing games
const gamesCache = new Map();

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please refresh the page.</h1>;
    }

    return this.props.children;
  }
}

const App = () => {
  const { t, i18n } = useTranslation();
  const [cards, setCards] = useState([]);
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem('cartItems');
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [userSettings, setUserSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enabledGames, setEnabledGames] = useState([]);
  const [allowedConditions, setAllowedConditions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [expansionNames, setExpansionNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [floorPrice, setFloorPrice] = useState(0);
  const [bulkSettings, setBulkSettings] = useState('');
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [username, setUsername] = useState('');
  const [showEnquiryPopup, setShowEnquiryPopup] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [excludedSkus, setExcludedSkus] = useState([]);
  const [exchangeRates, setExchangeRates] = useState(null);
  const [userCurrency, setUserCurrency] = useState('USD');

  const [searchParams, setSearchParams] = useState({
    term: '',
    game: '',
    set: '',
    page: 1,
    number: ''
  });
  const isFetchingRef = useRef(false);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  // Add the clearCart function
  const clearCart = useCallback(() => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
  }, []);

  // Add the addCustomItem function
  const addCustomItem = useCallback((item) => {
    setCartItems(prevItems => {
      const newItems = [...prevItems, { ...item, cartItemId: Date.now() }];
      localStorage.setItem('cartItems', JSON.stringify(newItems));
      return newItems;
    });
  }, []);

  const handleCloseEnquiryPopup = useCallback(() => {
    setShowEnquiryPopup(false);
  }, []);

  const handleContinueEnquiry = useCallback(() => {
    setShowEnquiryPopup(false);
    if (userSettings && userSettings.email) {
      const subject = encodeURIComponent('Please assess my cards');
      const body = encodeURIComponent('I would like to request an assessment of my cards.');
      window.open(`mailto:${userSettings.email}?subject=${subject}&body=${body}`, '_blank');
    }
  }, [userSettings]);

  const fetchUserSettings = useCallback(async () => {
    try {
      const url = `${API_URL}/api/user-settings/${username}`;
      console.log('Fetching user settings from URL:', url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        updateUserSettings(data);
        setUserCurrency(data.currency || 'USD');
      } else {
        const text = await response.text();
        console.error("Received non-JSON response:", text);
        throw new Error(`Expected JSON, but received ${contentType}: ${text.substring(0, 100)}...`);
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      setError(`Failed to fetch user settings: ${error.message}`);
      updateUserSettings(DEFAULT_USER_SETTINGS);
      setUserCurrency('USD');
    } finally {
      setIsLoading(false);
    }
  }, [username]);

  useEffect(() => {
    const path = window.location.pathname;
    const extractedUsername = path.split('/')[1];
    setUsername(extractedUsername || '');
  }, []);

  useEffect(() => {
    if (username) {
      fetchUserSettings().catch(err => {
        console.error("Error fetching user settings:", err);
        setError("Failed to load user settings. Please refresh the page.");
      });
    } else {
      // If no username, use default settings
      updateUserSettings(DEFAULT_USER_SETTINGS);
      setUserCurrency('USD');
      setIsLoading(false);
    }
  }, [username, fetchUserSettings]);

  const fetchExchangeRates = useCallback(() => {
    const hardcodedRates = {
      CAD: 1.350255,
      GBP: 0.753671,
      AUD: 1.452544,
      NZD: 1.607852,
      MXN: 19.415903,
      USD: 1 // Always include USD with a rate of 1
    };
    setExchangeRates(hardcodedRates);
  }, []);

  useEffect(() => {
    fetchExchangeRates();
  }, [fetchExchangeRates]);

  const fetchCatalog = useCallback(async (params) => {
    if (isFetchingRef.current) {
      console.log('Fetch already in progress, skipping');
      return;
    }

    isFetchingRef.current = true;
    setIsLoading(true);
    console.log('Fetching catalog with params:', params);

    try {
      const url = new URL(`${API_URL}/api/catalog`);
      const searchParams = new URLSearchParams({
        game: params.game,
        page: params.page.toString(),
        limit: itemsPerPage.toString(),
        set: params.set,
        search: params.term
      });
      
      if (params.number) {
        searchParams.append('number', params.number);
      }
      
      url.search = searchParams.toString();

      console.log('Fetching from URL:', url.toString());

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        if (response.status === 404) {
          throw new Error(`Catalog not found. The requested resource may not exist or has been moved. Please check your search parameters and try again.`);
        } else {
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }
      }

      const data = await response.json();
      console.log('Received data:', data);

      if (!data.items || !Array.isArray(data.items)) {
        throw new Error('Invalid data format received from the server');
      }

      // Filter the results based on the number parameter
      if (params.number) {
        const filteredItems = data.items.filter(item => {
          if (typeof item.number === 'string') {
            const itemNumber = item.number.replace(/^.*?(\d+)(?:\/\d+)?.*$/, '$1');
            return itemNumber === params.number;
          } else if (typeof item.number === 'number') {
            return item.number.toString() === params.number;
          }
          return false; // If item.number is neither a string nor a number, exclude it
        });
        setCards(filteredItems);
        setTotalPages(Math.ceil(filteredItems.length / itemsPerPage) || 1);
      } else {
        setCards(data.items);
        setTotalPages(data.totalPages || 1);
      }
      
      // Extract unique set names from the search results
      const uniqueSets = [...new Set(data.items.map(item => item.expansionName))];
      setExpansionNames(uniqueSets);

    } catch (error) {
      console.error('Error fetching catalog:', error);
      setError(`Failed to fetch catalog: ${error.message}`);
      setCards([]);
      setTotalPages(1);
      setExpansionNames([]);
    } finally {
      setIsLoading(false);
      isFetchingRef.current = false;
    }
  }, [itemsPerPage, API_URL]);

  const debouncedFetchCatalog = useMemo(
    () => debounce((params) => {
      if (!isInitialLoad && userSettings) {
        fetchCatalog({
          ...params,
          number: searchParams.number
        });
      }
    }, 300),
    [fetchCatalog, isInitialLoad, userSettings, searchParams.number]
  );

  useEffect(() => {
    console.log('Search params changed:', searchParams);
    if (!isInitialLoad && userSettings) {
      debouncedFetchCatalog(searchParams);
    }
  }, [searchParams, debouncedFetchCatalog, isInitialLoad, userSettings]);

  const updateUserSettings = (settings) => {
    setUserSettings(settings);

    let games = [];
    if (Array.isArray(settings.enabledGames)) {
      games = settings.enabledGames;
    } else if (typeof settings.enabledGames === 'string') {
      games = settings.enabledGames.split(',').map(game => game.trim());
    } else {
      games = DEFAULT_USER_SETTINGS.enabledGames;
    }
    
    games.sort((a, b) => a.localeCompare(b));
    setEnabledGames(games);

    gamesCache.set('enabledGames', {
      games: games,
      timestamp: Date.now()
    });

    if (settings.conditions) {
      setAllowedConditions(settings.conditions);
    }

    if (settings.floorPrice) {
      const parsedFloorPrice = parseFloat(settings.floorPrice);
      if (!isNaN(parsedFloorPrice)) {
        setFloorPrice(parsedFloorPrice);
      } else {
        setFloorPrice(0);
      }
    } else {
      setFloorPrice(0);
    }

    if (settings.bulkSettings) {
      setBulkSettings(settings.bulkSettings);
    }

    setExcludedSkus(settings.excludedSku?.map(String) || []);
  };

  const calculatePrice = useCallback((card, condition, printing) => {
    console.log(`Calculating price for: ${card.name} (${card.game})`);
    console.log(`Condition: ${condition}, Printing: ${printing}`);

    if (!userSettings || !exchangeRates) {
      console.log('User settings or exchange rates not available');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null, error: 'Settings unavailable' };
    }

    // First find the NM SKU for stepped pricing calculation
    const nmSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' && 
      sku.printingName === printing &&
      sku.condAbbr === 'NM'
    );

    if (!nmSku) {
      console.log('No NM SKU found for the selected printing');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null, error: 'No NM SKU found' };
    }

    // Get the actual SKU for the selected condition
    const currentSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' && 
      sku.printingName === printing &&
      sku.condAbbr === condition
    );

    // Get actual price for current condition
    const actualPrice = currentSku?.lowPrice || 0;

    // Use tcgplayerPrice if available, otherwise fall back to lowPrice for NM
    const nmPrice = nmSku.lowPrice;
    if (!nmPrice) {
      console.log('No price available for NM SKU');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null, error: 'No price available' };
    }

    // Calculate stepped price based on NM price
    let steppedPrice;
    switch (condition) {
      case 'NM': steppedPrice = nmPrice; break;
      case 'LP': steppedPrice = nmPrice * 0.8; break;
      case 'MP': steppedPrice = nmPrice * 0.64; break;
      case 'HP': steppedPrice = nmPrice * 0.512; break;
      case 'DM': steppedPrice = nmPrice * 0.4096; break;
      default: steppedPrice = nmPrice;
    }

    // Use TCGPlayer price only if it exists and is lower than stepped price
    const basePrice = actualPrice > 0 && actualPrice < steppedPrice ? actualPrice : steppedPrice;
    const priceSource = actualPrice > 0 && actualPrice < steppedPrice ? 'TCGplayer' : 'Stepped';

    if (basePrice === 0) {
      console.log('Base price is 0');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null, error: 'Invalid base price' };
    }

    console.log(`Using ${priceSource} price: $${basePrice}`);

    try {
      // Use default percentages
      let cashPercentage = parseFloat(userSettings.defaultCashPercentage || DEFAULT_USER_SETTINGS.defaultCashPercentage) / 100;
      let creditPercentage = parseFloat(userSettings.defaultCreditPercentage || DEFAULT_USER_SETTINGS.defaultCreditPercentage) / 100;

      console.log('Using default percentages:', { cashPercentage: cashPercentage * 100, creditPercentage: creditPercentage * 100 });

      // Check for expansion-specific rules
      if (userSettings.expansionRules && Array.isArray(userSettings.expansionRules)) {
        const expansionRule = userSettings.expansionRules.find(rule => 
          rule.game === card.gameName && rule.expansion === card.expansionName
        );

        if (expansionRule && expansionRule.conditions && expansionRule.conditions[condition]) {
        cashPercentage = parseFloat(expansionRule.conditions[condition].cash_percentage) / 100;
        creditPercentage = parseFloat(expansionRule.conditions[condition].credit_percentage) / 100;
        console.log(`Applied expansion-specific rule: Cash ${cashPercentage * 100}%, Credit ${creditPercentage * 100}%`);
        } else {
          console.log('No applicable expansion-specific rule found, falling back to general pricing rules');
          applyGeneralPricingRules();
        }
      } else {
        console.log('No expansion rules available, falling back to general pricing rules');
        applyGeneralPricingRules();
      }

      function applyGeneralPricingRules() {
        if (userSettings.pricing_rules && Array.isArray(userSettings.pricing_rules)) {
            console.log('Checking pricing rules:', userSettings.pricing_rules);
            const applicableRule = userSettings.pricing_rules.find(rule => 
              rule.game === card.gameName &&
              basePrice >= rule.min_value &&
              basePrice <= rule.max_value
            );

            if (applicableRule) {
              cashPercentage = applicableRule.cash_percentage / 100;
              creditPercentage = applicableRule.credit_percentage / 100;
              console.log(`Applied pricing rule: Cash ${cashPercentage * 100}%, Credit ${creditPercentage * 100}%`);
              console.log('Rule details:', applicableRule);
            } else {
              console.log('No applicable pricing rule found, using current percentages');
            }
          } else {
            console.log('No pricing rules available, using default percentages');
          }
      }

      let cashPrice = basePrice * cashPercentage;
      let creditPrice = basePrice * creditPercentage;

      const floorPrice = parseFloat(userSettings.floorPrice || DEFAULT_USER_SETTINGS.floorPrice);
      console.log('Floor price:', floorPrice);

      const isBulk = cashPrice < floorPrice && creditPrice < floorPrice;

      // Convert prices to user's currency
      const exchangeRate = exchangeRates[userCurrency] || 1;
      cashPrice *= exchangeRate;
      creditPrice *= exchangeRate;

      const result = {
        cashPrice: parseFloat(cashPrice.toFixed(2)),
        creditPrice: parseFloat(creditPrice.toFixed(2)),
        cashPercentage: cashPercentage * 100,
        creditPercentage: creditPercentage * 100,
        isBulk: isBulk,
        timestamp: Date.now(),
        currency: userCurrency,
        error: null,
        priceSource: priceSource
      };

      console.log(`Final calculated prices for ${card.name}:`, result);
      return result;
    } catch (error) {
      console.error('Error calculating price:', error);
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: Date.now(), error: error.message };
    }
  }, [userSettings, exchangeRates, userCurrency]);

  const addToCart = useCallback((card, condition, printing, quantity, basePrice, skuId, isCash, generatedPrice) => {
    const { cashPrice, creditPrice, cashPercentage, creditPercentage, isBulk, timestamp } = calculatePrice(card, condition, printing);
    
    // Use the generated price instead of the slider price
    const finalPrice = generatedPrice !== undefined ? generatedPrice : (isCash ? cashPrice : creditPrice);
    
    // Calculate the actual percentage based on the final price and base price
    const actualPercentage = (finalPrice / basePrice) * 100;

    const newItem = {
      ...card,
      condition,
      printing,
      quantity: Math.min(quantity, 4),
      basePrice,
      cashPrice: isCash ? finalPrice : cashPrice,
      creditPrice: isCash ? creditPrice : finalPrice,
      skuId,
      isCash,
      isBulk,
      timestamp,
      cartItemId: Date.now(),
      percentage: actualPercentage
    };
    setCartItems(prevItems => {
      const updatedItems = [...prevItems, newItem];
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  }, [calculatePrice]);

  const removeFromCart = useCallback((item) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.filter(cartItem => cartItem.cartItemId !== item.cartItemId);
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  }, []);

  const updateQuantity = useCallback((item, newQuantity) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.map(cartItem =>
        cartItem.cartItemId === item.cartItemId
          ? { ...cartItem, quantity: Math.min(Math.max(newQuantity, 1), 4) }
          : cartItem
      );
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  }, []);

  const togglePaymentType = useCallback((item) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.map(cartItem =>
        cartItem.cartItemId === item.cartItemId
          ? { ...cartItem, isCash: !cartItem.isCash }
          : cartItem
      );
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  }, []);

  const updateOfferPrice = useCallback((item, newPrice, isCash) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.map(cartItem =>
        cartItem.cartItemId === item.cartItemId
          ? { 
              ...cartItem, 
              [isCash ? 'cashPrice' : 'creditPrice']: parseFloat(newPrice),
              isCash: isCash,
              percentage: (parseFloat(newPrice) / cartItem.basePrice) * 100
            }
          : cartItem
      );
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  }, []);

  const startCheckout = useCallback(() => {
    setIsCheckingOut(true);
  }, []);

  const cancelCheckout = useCallback(() => {
    setIsCheckingOut(false);
  }, []);

  const goToMainPage = useCallback(() => {
    setIsCheckingOut(false);
  }, []);

  const completeCheckout = useCallback((checkoutData) => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
    setIsCheckingOut(false);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleSearchSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSearchLoading(true);
    setIsInitialLoad(false);
    
    try {
      let translatedTerm = searchTerm;
      if (isTranslationAvailable() && i18n.language !== 'en') {
        translatedTerm = await translateText(searchTerm, i18n.language, 'en');
      } else if (!isTranslationAvailable()) {
        console.warn("Translation is not available. Using original search term.");
      }
      
      setSearchParams(prev => ({
        ...prev,
        term: translatedTerm,
        page: 1
      }));
      
      // Trigger the search
      fetchCatalog({
        ...searchParams,
        term: translatedTerm,
        page: 1
      });
    } catch (error) {
      console.error("Error translating search term:", error);
      setError(`Failed to perform search: ${error.message}`);
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchTerm, i18n.language, searchParams, fetchCatalog]);

  const handleGameChange = useCallback((e) => {
    const newGame = e.target.value;
    setSearchParams(prev => ({
      ...prev,
      game: newGame,
      set: '',
      page: 1
    }));
    // We're not triggering a search here anymore
  }, []);

  const handleSetChange = useCallback((e) => {
    setSearchParams(prev => ({
      ...prev,
      set: e.target.value,
      page: 1
    }));
  }, []);

  const handleNumberFilterChange = useCallback((e) => {
    const inputValue = e.target.value;
    const processedNumber = inputValue.replace(/^.*?(\d+)(?:\/\d+)?.*$/, '$1');
    setSearchParams(prev => ({
      ...prev,
      number: processedNumber,
      page: 1
    }));
    // We're not triggering a search here anymore
  }, []);

  const handlePageChange = useCallback((newPage) => {
    console.log('Changing to page:', newPage);
    setSearchParams(prev => ({
      ...prev,
      page: newPage
    }));
    // Trigger the search for the new page
    fetchCatalog({
      ...searchParams,
      page: newPage
    });
  }, [fetchCatalog, searchParams]);

  const handleItemsPerPageChange = useCallback((e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setSearchParams(prev => ({
      ...prev,
      page: 1
    }));
    // Trigger the search with the new items per page
    fetchCatalog({
      ...searchParams,
      page: 1,
      limit: newItemsPerPage
    });
  }, [fetchCatalog, searchParams]);

  const getCurrencySymbol = (currency) => {
    const symbols = {
      USD: '$',
      CAD: 'C$',
      GBP: '£',
      AUD: 'A$',
      NZD: 'NZ$',
      MXN: 'MX$'
    };
    return symbols[currency] || '$';
  };

  const memoizedCardList = useMemo(() => (
    <CardList
      cards={cards}
      addToCart={addToCart}
      allowedConditions={allowedConditions}
      floorPrice={floorPrice}
      bulkSettings={bulkSettings}
      userSettings={userSettings}
      excludedSkus={excludedSkus}
      calculatePrice={calculatePrice}
      exchangeRates={exchangeRates}
      currencySymbol={getCurrencySymbol(userCurrency)}
      userCurrency={userCurrency}
    />
  ), [cards, addToCart, allowedConditions, floorPrice, bulkSettings, userSettings, excludedSkus, calculatePrice, exchangeRates]);

  if (error) {
    return <div>{t('Error')}: {error}</div>;
  }

  if (isCheckingOut) {
    return (
      <CheckoutPage
        cartItems={cartItems}
        updateQuantity={updateQuantity}
        removeFromCart={removeFromCart}
        completeCheckout={completeCheckout}
        cancelCheckout={cancelCheckout}
        goToMainPage={goToMainPage}
        userSettings={userSettings}
        username={username}
        calculatePrice={calculatePrice}
        togglePaymentType={togglePaymentType}
        updateOfferPrice={updateOfferPrice}
        addCustomItem={addCustomItem}
        currencySymbol={getCurrencySymbol(userCurrency)}
      />
    );
  }

  return (
    <ErrorBoundary>
      <div className="app">
        {showEnquiryPopup && (
          <EnquiryPopup
            onClose={handleCloseEnquiryPopup}
            onContinue={handleContinueEnquiry}
          />
        )}
        <div className="main-content">
          <div className="filters">
            <div className="search-and-upload">
              <form onSubmit={handleSearchSubmit} className="search-container">
                <div className="search-and-filter-container">
                  <input
                    type="text"
                    placeholder={t('Search by Name')}
                    className="search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <input
                    type="text"
                    placeholder={t('Filter by Number')}
                    className="number-filter-input"
                    value={searchParams.number || ''}
                    onChange={handleNumberFilterChange}
                  />
                  <button type="submit" className="search-button">{t('Search')}</button>
                </div>
              </form>
            </div>
            {!isTranslationAvailable() && (
              <p className="translation-warning">
                {t('Translation is not available. Searches will be performed with the original text.')}
              </p>
            )}
            <select className="filter-select" value={searchParams.game} onChange={handleGameChange}>
              <option value="">{t('Please select your game')}</option>
              {enabledGames.map((game, index) => (
                <option key={index} value={game}>{game}</option>
              ))}
            </select>
            <select className="filter-select" value={searchParams.set} onChange={handleSetChange}>
              <option value="">{t('All Sets')}</option>
              {expansionNames.map((set, index) => (
                <option key={index} value={set}>{set}</option>
              ))}
            </select>
            <select className="filter-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value="10">10 {t('per page')}</option>
              <option value="25">25 {t('per page')}</option>
              <option value="50">50 {t('per page')}</option>
            </select>
          </div>
          {isSearchLoading ? (
            <div className="loading-screen">
              <p>{t('Just fetching you the best prices...')}</p>
            </div>
          ) : isLoading ? (
            <div>{t('Loading')}...</div>
          ) : (
            <>
              {isInitialLoad ? (
                <Infographics />
              ) : (
                userSettings && memoizedCardList
              )}
              {!isInitialLoad && (
                <div className="pagination">
                  <button onClick={() => handlePageChange(searchParams.page - 1)} disabled={searchParams.page === 1}>{t('Previous')}</button>
                  <span>{t('Page')} {searchParams.page} {t('of')} {totalPages}</span>
                  <button onClick={() => handlePageChange(searchParams.page + 1)} disabled={searchParams.page === totalPages}>{t('Next')}</button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="cart-container">
          <Cart
            cartItems={cartItems}
            removeFromCart={removeFromCart}
            checkout={startCheckout}
            updateQuantity={updateQuantity}
            togglePaymentType={togglePaymentType}
            updateOfferPrice={updateOfferPrice}
            userSettings={userSettings}
            clearCart={clearCart}
            addCustomItem={addCustomItem}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

// Debounce function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default App;
